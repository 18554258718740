import { Button, Form } from 'front'
import { useModalState } from 'hooks'
import { useState } from 'react'
import {
    Alert,
    Modal,
    ToggleButton,
    ToggleButtonGroup,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap'
import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { Project } from 'tracker/api/project.api'

export default function SendBackSuppInfo({ label, url, method, ...props }) {
    const router = useRouter()
    const projectId = parseInt(router.query?.project_id)
    const modal = useModalState()
    const [data, setData] = useState({
        note: '',
        clearServices: true,
    })
    const queryClient = useQueryClient()
    const [apiMessages, setApiMessages] = useState([])
    const { mutate, isLoading, isError, error } = useMutation({
        mutationFn: () => {
            return axios.request({ url, method: method ?? 'POST', data }).then((res) => res.data)
        },
        onSuccess: (data) => {
            setApiMessages(data?.messages)
        },
    })

    const onHide = () => {
        setData({ note: '', clearServices: true })
        setApiMessages(null)
        queryClient.invalidateQueries(Project.keys.project(projectId))
        modal.hide()
    }

    return (
        <div>
            <Button onClick={modal.show}>{label ?? 'Send to Supplemental Info'}</Button>
            {modal.isOpen && (
                <Modal show={modal.isOpen} onHide={onHide}>
                    <Modal.Header closeButton>
                        <Modal.Title>Support Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {apiMessages?.length > 0 &&
                            apiMessages.map((message, i) => (
                                <Alert
                                    key={i}
                                    variant={'soft-' + (message?.success ? 'success' : 'danger')}
                                    className='mb-3'
                                >
                                    <div className='d-flex align-items-center gap-2'>
                                        <span
                                            className={
                                                'fas fa-' +
                                                (message?.success
                                                    ? 'check'
                                                    : 'exclamation-triangle') +
                                                ' me-1'
                                            }
                                        />
                                        {message?.message}
                                    </div>
                                </Alert>
                            ))}
                        {isError && (
                            <div className='d-flex flex-column gap-3'>
                                {error?.message && (
                                    <Alert variant='soft-danger'>
                                        <span className='fas fa-exclamation-triangle me-1' />
                                        {error?.message}
                                    </Alert>
                                )}
                                {error?.response?.data?.errors?.length > 0 && (
                                    <Alert variant='soft-danger'>
                                        <div className='d-flex flex-column gap-3'>
                                            {error?.response?.data?.errors?.map((error, i) => (
                                                <div key={i} className='d-flex gap-2'>
                                                    <span className='fas fa-exclamation-triangle me-1' />
                                                    <span>{error.message}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </Alert>
                                )}
                            </div>
                        )}
                        <div className='d-flex flex-column gap-3'>
                            <div>
                                <Form.Label htmlFor='supp-info-note'>Note</Form.Label>
                                <Form.Control
                                    id='supp-info-note'
                                    as='textarea'
                                    rows={5}
                                    name='note'
                                    value={data.note}
                                    onChange={(e) => setData({ ...data, note: e.target.value })}
                                    ignoreGlobalDisable
                                />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center gap-1'>
                                    <Form.Label className='mb-0'>Clear Services?</Form.Label>
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip>
                                                This will cancel incomplete tasks and set the
                                                project to Tracking
                                            </Tooltip>
                                        }
                                    >
                                        <i className='far fa-circle-question' />
                                    </OverlayTrigger>
                                </div>
                                <YesNoToggle
                                    name={'clearServices'}
                                    value={data.clearServices}
                                    onChange={(value) => setData({ ...data, clearServices: value })}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='d-flex justify-content-between'>
                        <Button variant='ghost' onClick={onHide}>
                            Cancel
                        </Button>
                        <Button
                            onClick={mutate}
                            variant='primary'
                            loading={isLoading}
                            disabled={data.note.length === 0 || apiMessages?.length > 0}
                        >
                            <span className='fas fa-paper-plane me-1' /> Send to Supp Info
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    )
}

function YesNoToggle({ name, value, onChange }) {
    return (
        <ToggleButtonGroup
            name={name}
            value={value}
            type='radio'
            className='btn-group-segment'
            size='xs'
        >
            <ToggleButton value={false} variant='' onClick={() => onChange(false)}>
                <span className='fw-bold'>No</span>
            </ToggleButton>
            <ToggleButton value={true} variant='' onClick={() => onChange(true)}>
                <span className='fw-bold'>Yes</span>
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
